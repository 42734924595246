import {createApp} from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

/* import the fontawesome */
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEnvelope, faMobileScreen, faLocationDot, faUser, faRobot, faBug} from '@fortawesome/free-solid-svg-icons'

library.add(faEnvelope, faMobileScreen, faLocationDot, faUser, faRobot, faBug)

const mountEl = document.querySelector("#app");
const app = createApp(App, {...mountEl.dataset})

app.config.globalProperties.$filters = {
    capitalize(value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase()
    }
}

app.mount('#app')
