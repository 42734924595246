<template>
  <div class="flex flex-col md:flex-row grow justify-center items-center self-center m-auto">
    <a class="cube block w-60 mr-5"
       :href="resumeLink"
       target="_blank"
    >
      <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 59 59" xml:space="preserve">
          <defs>
            <path id="square" d="M43.19,47H4.81C2.706,47,1,45.294,1,43.19V4.81C1,2.706,2.706,1,4.81,1h38.38
              C45.294,1,47,2.706,47,4.81v38.38C47,45.294,45.294,47,43.19,47z"/>
          </defs>
        <use href="#square" class="fill-main-semi-light" transform="translate(8 8)"/>
        <use href="#square" class="fill-main-dark ani"/>
        <text x="16" y="40" class="fill-white ani" font-size="11">OPEN</text>
      </svg>
    </a>
    <a class="cube block w-60 ml-0 md:ml-20 mt-20 md:mt-0" :href="resumeLink"
       download>
      <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 59 59" xml:space="preserve">
          <defs>
            <path id="square" d="M43.19,47H4.81C2.706,47,1,45.294,1,43.19V4.81C1,2.706,2.706,1,4.81,1h38.38
              C45.294,1,47,2.706,47,4.81v38.38C47,45.294,45.294,47,43.19,47z"/>
          </defs>
        <use href="#square" class="fill-main-semi-light" transform="translate(8 8)"/>
        <use href="#square" class="fill-main-dark ani"/>
        <text x="16" y="29" class="fill-white ani" font-size="11">DOWN</text>
        <text x="16" y="40" class="fill-white ani" font-size="11">LOAD</text>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ResumePage',
  computed: {
    resumeLink() {
      return this.$root.getUrlTo('/file/TymofeievMaksymResume.pdf')
    }
  }
}
</script>

<style scoped lang="scss">

.ani {
  transition: transform 0.5s;
}

.cube:hover {
  .ani {
    transform: translate(10%, 10%);
  }
}

.cube:active {
  .ani {
    transform: rotate(-50deg);
  }
}
</style>