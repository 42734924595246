<template>
  <div class="flex flex-col m-auto items-center justify-center text-white text-4xl">
    <h2>Contact information:</h2>
    <address class="my-6 not-italic text-3xl">
      <p class="my-3">
        <a href="mailto:tymofeiev.m@gmail.com">
          <font-awesome-icon icon="fa-solid fa-envelope" class="fa-fw text-main-light"></font-awesome-icon>
           tymofeiev.m@gmail.com
        </a>
      </p>
      <p class="my-3">
        <a href="tel:+14168908703">
          <font-awesome-icon icon="fa-solid fa-mobile-screen" class="fa-fw text-main-light"></font-awesome-icon>
           +1 (416) 890-87-03
        </a>
      </p>
      <p class="my-3">
        <font-awesome-icon icon="fa-solid fa-location-dot" class="fa-fw text-main-light"></font-awesome-icon>
         Calgary, AB, Canada
      </p>
    </address>
  </div>
</template>


<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
export default {
  name: 'ContactsPage',
  components: {
    FontAwesomeIcon
  }
}
</script>

<style scoped lang="scss">
.back {
  position: absolute;
  z-index: 1;
}

.address {
  z-index: 2;
  @apply flex flex-col bg-main-light text-main-dark p-10 font-bold text-xl;
  filter: drop-shadow(15px 15px 0 #9FACBD);
  p {
    @apply my-4
  }
}

</style>