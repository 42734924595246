<template>
  <div class="h-screen">
    <div class="flex flex-col h-full justify-center items-center">
      <div class="flex flex-col h-full w-full" v-if="currentPage !== null">
        <Transition name="fade" mode="out-in">
          <component :is="currentPage"></component>
        </Transition>
      </div>
      <HelloWorld @navigate="navigate" :minimize="minimize" :class="{
        'md:absolute transition-all duration-500 items-center': true,
        'md:left-[10px] md:right-[10px] md:w-[200px] w-full': minimize,
        'p-6 md:h-full md:m-[10%]': !minimize,
      }"/>
    </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import AssistantPage from "@/components/AssistantPage.vue";
import ContactsPage from "@/components/ContactsPage.vue";
import ResumePage from "@/components/ResumePage.vue";

export default {
  name: 'App',
  props: {
    locale: String
  },
  components: {
    HelloWorld,
    AssistantPage,
    ContactsPage,
    ResumePage
  },
  data() {
    return {
      page: null
    }
  },
  methods: {
    navigate(page) {
      this.page = page
    },
    getUrlTo(url) {
      return '//max-tymofeiev.com' + url;
    }
  },
  computed: {
    minimize() {
      return this.page !== null
    },
    currentPage() {
      return this.page;
    }
  }
}
</script>

<style lang="scss">
body {
  @apply w-full bg-main font-mono;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
