<template>

  <div class="flex flex-col justify-center items-center self-center m-auto md:container">
    <div class="md:container mx-10 md:mx-0">
      <p class="text-white font-bold text-xl md:text-2xl mb-6">You can talk with my assistant. He is AI and know a lot
        about me,
        but be careful he is still learning.</p>
      <div class="bg-white p-4 rounded-lg shadow-lg">
        <div class="min-h-[40vh] max-h-[40vh] md:min-h-[45vh] md:max-h-[45vh] overflow-y-scroll scroll-p-0 scroll-m-0"
             ref="chatContainer">
          <div class="flex items-start mb-4 scroll-p-0 scroll-m-0" v-for="(message, k) in messages" :key="k">
            <div class="bg-grey-300 text-gray-700 w-10 h-10 p-6 rounded-full flex items-center justify-center mr-2">
              <font-awesome-icon
                  :icon="message.role === 'user' ? 'fa fa-user' : message.role === 'error' ? 'fa fa-bug' : 'fa fa-robot'"></font-awesome-icon>
            </div>
            <div class="text-black p-3">
              <p class="font-bold">{{ getRoleName(message.role) }}</p>
              <p v-for="(content,ck) in message.content" :key="ck" v-html="markdown(content.text.value)">
              </p>
            </div>
          </div>
          <div ref="scrollPoint" style="height: 200px"></div>
        </div>
        <div class="mt-4 flex flex-row items-center">
          <input type="text" placeholder="Type your message..." v-model="messageToSend"
                 class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                 v-on:keyup.enter="send">
          <button @click="send" class="ml-2 bg-main hover:bg-main-semi-dark text-white px-4 py-2 rounded-lg"
                  :disabled="sendInProgress">
            {{ sendCaption }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import markdownit from 'markdown-it'

const mila = require("markdown-it-link-attributes");

import {get} from 'lodash/object'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const md = markdownit()
md.use(mila, {
  attrs: {
    target: "_blank",
    class: "text-main-semi-light hover:text-main-semi-dark"
  },
});

export default {
  name: 'AssistantPage',
  components: {FontAwesomeIcon},
  data() {
    return {
      messages: [],
      messageToSend: null,
      threadID: null,
      sendInProgress: false,
    }
  },
  computed: {
    sendCaption() {
      return this.sendInProgress ? 'sending...' : 'send'
    },
  },
  methods: {
    markdown(mark) {
      return md.render(mark);
    },
    getRoleName(role) {
      return role === 'user' ? 'You' : (role === 'error') ? 'Error' : 'Assistant'
    },
    getFileUrlByHash(hash) {
      let filesMap = {
        'file-GzoRbSpIjieT0TBn9kvCtbgP': this.$root.getUrlTo('/file/TymofeievMaksymResume.pdf'),
        'file-ZyIkgowkrBkW17Zk5KPSYkvT': this.$root.getUrlTo('/file/TymofeievMaksymResume.pdf'),
        'file-2BQ2Fr0NAakLyNylGmc1o9Ax': this.$root.getUrlTo('/file/TymofeievMaksymResume.pdf'),
        'file-8AKjlv7KwXp4zFj45ldWX2Ut': this.$root.getUrlTo('/file/TymofeievMaksymRecomendation.pdf'),
        'file-jC6MGPZmKbDHrOgXZw57vEzM': this.$root.getUrlTo('/file/TymofeievMaksymRecomendation.pdf'),
      }
      return filesMap[hash];
    },
    parseResponse(data) {
      data.forEach((message) => {
        message.content.forEach((content, index) => {
          let anotations = Object.values(get(content, 'text.annotations', {}))
          if (content.type === 'text' && anotations.length > 0) {
            anotations.forEach((ano) => {
              if (ano.type === 'file_citation') {
                message.content[index].text.value = content.text.value.replaceAll(ano.text,
                    ' [[link]](' + this.getFileUrlByHash(ano.file_citation.file_id) + ') '
                )
              }
            })
          }
        })
        this.messages.push(message)
      })
    },
    send() {
      this.sendInProgress = true
      let url = location.href.replace(/\/$/, "");
      let mode = 'cors'

      // for local development
      if (url === 'http://localhost:8080') {
        url = 'http://localhost:22080'
        mode = "cors";
      }

      const messageToSend = this.messageToSend;

      this.messages.push({content: [{type: 'text', text: {value: messageToSend}}], role: 'user'})
      this.messageToSend = null
      this.scrollToLastMessage()

      fetch(url + "/api/message", {
        method: "POST",
        mode: mode,
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          message: messageToSend,
          thread: this.threadID
        }),
      })
          .then((response) => response.text())
          .then((data) => {
            let rawData = JSON.parse(data)
            this.parseResponse(rawData.data)
            this.threadID = rawData.thread;
            this.scrollToLastMessage()
          })
          .catch((err) => console.log("Error", err))
          .finally(() => this.sendInProgress = false);
    },
    scrollToLastMessage() {
      const lastChildElement = this.$refs.scrollPoint;

      lastChildElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>