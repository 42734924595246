<template>
  <div
      :class="
      {
        'flex items-center z-10 items-center justify-center': true,
        'md:flex-row flex-col': !isCollapsed,
        'flex-row md:flex-col': isCollapsed,
      }">
    <img
        alt="Photo of Max Tymofeiev"
        :src="$root.getUrlTo('/img/max.png')"
        @click="navigate(null)"
        :class="
          {
            'transition-all duration-1000 rounded-full mb-5': true,
            'w-1/2 md:w-1/4': !isCollapsed,
            'w-[80px] h-[80px] md:w-[150px] md:h-[150px] mr-5 md:mr-0': isCollapsed,
          }"
    />
    <div :class="{'md:ml-20': !isCollapsed}">
      <div class="text-white text-xl md:text-3xl" v-if="!isCollapsed">
        <span>Hi, my name is&nbsp;</span>
        <h1 class="font-bold inline-block m-0 p-0">Maksym Tymofeiev.</h1>
        <span> However, you can call me <b>Max</b>.</span>
      </div>
      <div class="text-white text-xl md:text-3xl mt-5" v-if="!isCollapsed">
        <span>I am not online right now, but fortunately technology will help us. Please, feel free to talk with my AI assistant or contact me directly.</span>
      </div>
      <div
          :class="
          {
            'text-white flex items-center justify-center': true,
            'text-sm flex-row md:flex-col': isCollapsed,
            'text-xl md:w-1/2 mt-10 flex-row': !isCollapsed,
          }">
        <button @click="navigate('ResumePage')"
                :class="{
                    'my-btn': true,
                    'active': active === 'ResumePage',
                    'my-3': isCollapsed,
                }"
                type="button">Resume
        </button>
        <button @click="navigate('AssistantPage')"
                :class="{
                    'my-btn': true,
                    'active': active === 'AssistantPage',
                    'my-3': isCollapsed,
                }"
                type="button">
          Assistant
        </button>
        <button @click="navigate('ContactsPage')"
                :class="{
                    'my-btn': true,
                    'active': active === 'ContactsPage',
                    'my-3': isCollapsed,
                }"
                type="button">
          Contacts
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    minimize: Boolean,
  },
  methods: {
    navigate(page) {
      this.$emit('navigate', page)
    }
  },
  computed: {
    isCollapsed() {
      return this.minimize;
    },
    active() {
      return this.$root.page;
    }
  }
}
</script>
<style lang="scss">
.my-btn {
  @apply bg-transparent aspect-square my-5 flex justify-center items-center m-auto font-bold
  w-[60px] h-[60px] md:w-[120px] md:h-[120px] text-xs md:text-xl mx-2 md:my-0
  text-white
  hover:text-main-semi-light hover:border-main-semi-light
  border-4 border-main-light
  transition-all duration-500 rounded-full;
  &.active {
    @apply bg-main-dull;
  }
}
</style>
